@import "bootstrap/dist/css/bootstrap.min.css";

.theme-toggle-btn {
  display: none;
}

:root {
  --background-primary: #2e2e2e;
  --background-secondary: #232323;
  --pink-step-1: #cf31ff;
  --pink-step-2: linear-gradient(
    90deg,
    #f978ff,
    #f273ff,
    #eb6eff,
    #e469ff,
    #dc64ff,
    #d45fff,
    #cc5bff,
    #c357ff
  );
  --blue-secondary: #149dcc;
  --purple-step-1: #00f;
  --text-color: #ffffff;
  --footer: #f0f;
  --purple-step-2: #9900ff;
  --purple-step-3: #8e7cc3;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif !important;
  background: var(--pink-step-2) !important;
  color: var(--text-color) !important;
}

.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh !important;
  background: var(--pink-step-2);
  flex-direction: column;
}

.login-card {
  background: var(--pink-step-1);
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: 400px;
  width: 100%;
  min-height: 300px;
  text-align: center;
  padding: 40px;
  border-radius: 80px;
  border: 2px solid var(--purple-step-1);
}

.login-card h2 {
  color: var(--text-color);
  font-weight: 500;
  margin: 0;
}

.login-card .msg {
  margin-top: 0;
  color: var(--text-color);
}

.login-form {
  display: flex;
  flex-direction: column;
}

.login-title {
  margin-bottom: 50px;
}

.login-form input {
  padding: 12px;
  margin-bottom: 20px;
  background-color: var(--pink-step-1);
  border: 2px solid var(--purple-step-1);
  border-radius: 4px;
  color: var(--text-color);
}

.login-form input:focus-visible {
  outline: none;
}

.login-form input::placeholder {
  color: var(--text-color);
}

.links-container {
  margin-top: 10px;
  text-align: center;
}

.forgot-password,
.create-account {
  margin: 5px 0;
}

.forgot-password a,
.create-account a {
  text-decoration: underline;
  color: var(--text-color);
  font-size: 14px;
}

.forgot-password a:hover,
.create-account a:hover {
  text-decoration: underline;
}

.or-text {
  font-size: 16px;
  color: #ffffff;
  margin: 0 10px;
}

.primary-button {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  text-transform: uppercase;
}

.primary-button {
  background: var(--blue-secondary);
  color: var(--text-color);
  border: 2px solid var(--purple-step-1);
}

.login-form .form-control {
  color: #fff !important;
}

.login-form .form-control:focus {
  background-color: transparent !important;
  border: 2px solid var(--purple-step-1) !important;
  box-shadow: none !important;
}

.dashboard {
  background: var(--pink-step-2);
  color: #fff;
  min-height: calc(100vh - 66px);
  padding-bottom: 80px;
}

.dashboard .card {
  border: 0 !important;
  background: transparent;
}

.dashboard .card-logo {
  width: 200px;
  background: #828282;
  margin: auto;
  height: 200px;
  border-radius: 30px;
  border: 2px solid var(--background-primary);
}

.dashboard .card-logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
}

.dashboard .card-body h5 {
  color: #fff !important;
}

.dashboard .card-body p {
  color: #fff !important;
}
.dashboard .dashbord-btn {
  padding: 10px 50px !important;
  border: 2px solid var(--background-primary) !important;
  color: #fff !important;
}

.logout-btn {
  border: 2px solid var(--background-primary) !important;
  color: #fff !important;
}

.dashboard .status-indicator {
  width: 10px;
  height: 10px;
  border-radius: 10px;
}

.footer {
  background: var(--footer);
}

.header-t {
  color: var(--text-color);
  background: var(--pink-step-1);
  padding: 20px;
  text-align: center;
  position: relative;
}
.tab-style .nav-tabs {
  justify-content: center;
  gap: 2px;
  background: var(--purple-step-2);
}

.tab-style .nav-tabs .nav-link {
  background: var(--purple-step-3);
  color: var(--text-color);
  border-bottom: 2px solid #000;
  padding: 10px 50px;
}

.tab-style .nav-tabs .nav-link.active {
  background: var(--purple-step-3);
  color: var(--text-color);
  border-bottom: 2px solid transparent;
  padding: 10px 50px;
}

.form-control {
  padding: 12px !important;
  margin-bottom: 20px !important;
  background-color: var(--pink-step-1) !important;
  border: 2px solid var(--purple-step-1) !important;
  border-radius: 4px !important;
}

.logout {
  position: absolute;
  right: 10px;
}

.list-style {
  list-style: none;
  padding: 0;
}
.list-style li {
  padding: 10px;
  border: 1px solid #686868;
}
.list-style li a {
  color: #fff;
  text-decoration: none;
}

svg {
  fill: #9900ff;
}

.list-container {
  margin: 0 auto;
  max-width: 90%;
  background: var(--pink-step-1);
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border-radius: 20px;
  border: 2px solid #000;
  padding: 10px;
}

.list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.list-header,
.list-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.list-header {
  font-weight: bold;
}

.list-item a {
  color: blue;
}

.list-item a:hover {
  text-decoration: underline;
}

.list-item span {
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.comman-card {
  background: var(--pink-step-1);
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 400px;
  min-height: 300px;
  text-align: center;
  padding: 40px;
  border-radius: 80px;
  border: 2px solid var(--purple-step-1);
}

.comman-card h2 {
  color: var(--text-color);
  font-weight: 500;
  margin: 0;
}

.comman-card .msg {
  margin-top: 0;
  color: var(--text-color);
}

.comman-form {
  display: flex;
  flex-direction: column;
}

.comman-title {
  margin-bottom: 50px;
}

.comman-form input {
  padding: 12px;
  margin-bottom: 20px;
  background-color: var(--pink-step-1);
  border: 2px solid var(--purple-step-1);
  border-radius: 4px;
  color: var(--text-color);
}

.comman-form input:focus-visible {
  outline: none;
}

.comman-form input::placeholder {
  color: var(--text-color);
}

.password-change-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.password-change-modal {
  position: relative;
  background: #f8bbd0;
  padding: 30px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  z-index: 1050;
  text-align: center;
  border: 1px solid #f48fb1;
}

.password-change-modal h3 {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #d81b60;
}

.password-change-modal .form-group {
  margin-bottom: 15px;
}

.password-change-modal .form-group label {
  font-weight: bold;
  text-align: left;
  display: block;
  color: #d81b60;
}

.password-change-modal .form-control {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #d81b60;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  background-color: #fce4ec;
}

.password-change-modal button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: #d81b60;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}

.password-change-modal button:hover {
  background-color: #c2185b;
}

.password-change-modal .btn-secondary {
  width: 100%;
  padding: 10px;
  background-color: #f8bbd0;
  color: #d81b60;
  border: 1px solid #f48fb1;
  margin-top: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.password-change-modal .btn-secondary:hover {
  background-color: #f48fb1;
  color: #fff;
}

.alert-warning {
  font-size: 16px;
  cursor: pointer;
  padding: 15px;
  position: relative !important;
  border-radius: 0px !important;
  top: 0;
  left: 0%;
  width: 100% !important;
  margin: auto;
  z-index: 1050;
  text-align: center;
  transform: translateX(0%);
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  color: #721c24;
}

.disabled-screen {
  transition: opacity 0.3s ease;
}

.badge_design-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  background: #cfcfcf;
}

.badge_design-wrapper .badge_design-container {
  width: 100%;
  height: 100vh;
  border: 1px solid #000;
  background: #cee2f2;
}

.badge_design-container .badge_design-container-body {
  height: calc(100vh - 82px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: fit-content;
  margin: auto;
}

.badge_design-wrapper .badge_design-sidebar {
  width: 100%;
  max-width: 350px;
  height: 100vh;
  border: 1px solid #000;
  background: #cfe2f3;
}

.badge_design-sidebar-header {
  display: flex;
  align-items: center;
  padding: 0 8px;
}

.badge_design-sidebar-header h1 {
  flex: 1;
}

.badge_design-sidebar-body {
  height: calc(100vh - 122px);
  overflow-y: auto;
  border-bottom: 2px solid #000;
  border-top: 2px solid #000;
}

.badge_design-save-btn {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  width: 100%;
  border-radius: 0 !important;
  text-align: center;
  color: #000 !important;
  text-transform: uppercase;
  font-size: 22px !important;
}

.badge_design-accordion .accordion-item {
  border-radius: 0 !important;
  background: transparent !important;
  margin: 0 !important;
}

.badge_design-accordion .accordion-item .accordion-header button {
  border-radius: 0 !important;
  background: transparent !important;
  border-bottom: 2px solid #000;
  box-shadow: none !important;
  position: relative;
  padding-left: 30px;
  text-align: center;
  justify-content: center;
  font-size: 18px;
}

.badge_design-accordion .accordion-item .accordion-header button::after {
  position: absolute;
  right: calc(100% - 25px);
  transform: rotate(-90deg);
}

.badge_design-accordion
  .accordion-item
  .accordion-header
  button:not(.collapsed):after {
  transform: rotate(0deg);
}

.badge_design-accordion .accordion-item .accordion-body {
  background: #fff;
  border-bottom: 2px solid #000;
}

.change-pass-modal {
  backdrop-filter: blur(3px);
}

.change-pass-modal .modal-content {
  background-color: var(--pink-step-1);
  border: 2px solid #2900f8;
  z-index: 99;
}

.change-pass-modal .modal-content .modal-title {
  color: #ffffff;
}

.change-pass-modal .modal-content .modal-body .form-label {
  display: block;
  text-align: left;
  color: #ffffff;
}
.change-pass-modal .modal-content .modal-body .form-control {
  color: #ffffff;
}
.change-pass-modal .modal-content .modal-body .btn-primary {
  background-color: #b458dc;
}

.theme-container {
  background-color: #00000000;
}
@media screen and (max-width: 1199px) {
  .comman-card {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .footer.fixed-bottom .d-flex.mob {
    flex-direction: column !important;
    align-items: start !important;
    gap: 10px;
  }
  .footer.fixed-bottom .d-flex.mob .btn.dashbord-btn {
    padding: 10px 20px !important;
    width: 100%;
  }
  .footer.fixed-bottom .d-flex.mob .d-flex {
    white-space: nowrap !important;
    width: 100%;
    justify-content: center;
  }
  .cards-rows .col-md-4:last-child {
    margin-bottom: 70px;
  }
  .tab-style .nav-tabs .nav-link,
  .tab-style .nav-tabs .nav-link.active {
    padding: 10px 10px;
    font-size: 14px;
  }
  .tab-style .nav-tabs {
    flex-wrap: nowrap;
  }
  .tab-content #uncontrolled-tab-example-tabpane-AdminDashboard {
    width: 95%;
    padding: 5px;
  }
  .login-card {
    width: 95%;
    padding: 40px 20px;
  }
  .comman-card {
    width: 100%;
  }
}
.btn.btn-primary {
  padding: 12px;
}

.docs {
  font-size: 100px;
}
.comman-form input[type="checkbox"] {
  margin-bottom: 0px !important;
}

.table-responsive {
  width: 100%;
  max-width: 1024px;
  margin: 20px auto;
}
.table-responsive .table.table-striped {
  background-color: transparent;
}
.table-responsive .table.table-striped th {
  background-color: #ff14f8;
}
.table-responsive .table.table-striped tr td {
  background-color: transparent !important;
}
.table-responsive .table.table-striped tr:nth-child(odd) {
  background-color: #e264f8;
  border-bottom: 0px solid transparent;
}
.table-responsive .table.table-striped tr:nth-child(even) {
  background-color: #ff14f8;
  border-bottom: 0px solid transparent;
}

.order-list.dashboard {
  min-height: 100vh !important;
}

.comman-card .comman-title h2 {
  font-size: 28px !important;
}
.comman-card .comman-title {
  margin-bottom: 30px;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--pink-step-2);
}

::-webkit-scrollbar-thumb {
  background: #26012f;
}

::-webkit-scrollbar-thumb:hover {
  background: #26012f;
}

.loading {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}
.loading__letter {
  font-size: 44px;
  font-weight: normal;
  font-weight: 600;
  letter-spacing: 4px;
  text-transform: uppercase;

  color: #26012f;
  animation-name: bounce;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.loading__letter:nth-child(2) {
  animation-delay: 0.1s;
}
.loading__letter:nth-child(3) {
  animation-delay: 0.2s;
}
.loading__letter:nth-child(4) {
  animation-delay: 0.3s;
}
.loading__letter:nth-child(5) {
  animation-delay: 0.4s;
}
.loading__letter:nth-child(6) {
  animation-delay: 0.5s;
}
.loading__letter:nth-child(7) {
  animation-delay: 0.6s;
}
.loading__letter:nth-child(8) {
  animation-delay: 0.8s;
}
.loading__letter:nth-child(9) {
  animation-delay: 1s;
}
.loading__letter:nth-child(10) {
  animation-delay: 1.2s;
}

@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-40px);
  }
  80%,
  100% {
    transform: translateY(0px);
  }
}

@media (max-width: 700px) {
  .loading__letter {
    font-size: 50px;
  }
}

@media (max-width: 340px) {
  .loading__letter {
    font-size: 40px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.login-screen {
  animation: fadeIn 0.5s ease-in-out;

  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.view-cam {
  height: 100%;
  border: 2px solid black;
  border-radius: 8px;
  transition: border-color 0.3s ease-in-out;
  position: absolute;
  left: 0px;
  top: 0px;
  border-radius: 0px;
  width: 100%;
  min-height: 100vh;
  object-fit: cover;
}

.cam-container {
  position: relative;
}

.cam-container h1 {
  z-index: 9;
  padding-top: 50px;
  position: relative;
}

.cam-container h6 {
  z-index: 9;
}

.search-input {
  max-width: 800px;
  margin: 20px auto;
  position: fixed !important;
  z-index: 8;
  left: 50%;
  transform: translateX(-50%);
  bottom: 30px;
  background-color: #ffffff5b;
  border-radius: 8px !important;
  color: #fff;
  width: 95%;
  border: 1px solid #fff;
}
.search-input input {
  color: #fff;
  border-radius: 0px !important;
}

.search-input input::placeholder {
  color: #fff;
}

.dropdown-menu {
  position: absolute;
  bottom: 35px;
  z-index: 10;
  display: block;
  width: 100%;
  background: rgb(0 0 0 / 18%) !important;
  border: 1px solid #000000;
  backdrop-filter: blur(5px);
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  padding: 0px !important;
  display: block;
  max-height: 145px;
  overflow-y: auto;
}

.dropdown-menu li:not(:last-child) {
  border-bottom: 1px solid #000000;
}

.dropdown-menu li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding: 7px 10px;
}

.dropdown-menu li p {
  color: #ffffff;
  font-size: 14px;
  margin: 0px 0px;
}

.dropdown-menu li p:hover {
  background-color: transparent;
  color: #ffffff;
}

.dropdown-menu li button.btn-success {
  font-size: 13px;
  width: 100%;
  max-width: 150px;
}

.cam-container h1 svg {
  position: relative;
  z-index: 9;
  width: 38px;
  fill: #ffffff90;
}

.green-box {
  position: relative;
  z-index: 9;
  width: 100%;
  max-width: 300px;
  background-color: rgba(3, 204, 3, 0.774);
  border: 1px solid green;
  border-radius: 30px;
  padding: 30px 40px;
  height: 300px;
}

.green-box span {
  font-size: 18px;
}

.green-box .badge-print-btn {
  position: absolute;
  width: 90%;
  margin: auto;
  bottom: 25px;
  left: 50%;
  padding: 17px 20px;
  border-radius: 14px;
  font-size: 18px;
  background-color: #ffffff90;
  color: #fff;
  transform: translateX(-50%);
}
.badge_design-container-body .konvajs-content canvas {
  height: 100% !important;
  width: 100% !important;
  max-width: 288px !important;
  max-height: 432px !important;
}

/* new css */

.custom-btn {
  background: #ff14f8;
  border: none;
  color: white;
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 10px;
}

.custom-btn:hover {
  background: #d80250;
}

.modal-overlay {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.modal-content {
  background: linear-gradient(135deg, #a14efc, #ff70a6);
  padding: 20px;
  width: 380px !important;
  text-align: center;
  border-radius: 8px !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  color: white;
  position: relative;
}

.modal-heading {
  margin-bottom: 15px;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
  color: white;
}

@media (max-width: 600px) {
  .modal-content {
    width: 90%;
  }
}


.cam-container  .btn.dashbord-btn{
  border: 1px solid #ffffff;
  color: #ffffff;
  position: absolute;
  right: 7%;
  top: 56px;
  z-index: 3;
}